.custom-scrollbar {
    scrollbar-width: none;
    scrollbar-color: #4a5568 #edf2f7;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #4a5568;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: #edf2f7;
}